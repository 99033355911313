import './App.css';
import Scene from './components/Scene';



function App() {
  return (
    <div>
      <Scene />
    </div>
  );
}
export default App;

